<template>
  <div class="login-page">
    <transition name="el-zoom-in-top">
      <el-row
        class="login-container"
        type="flex"
        justify="center"
        align="middle"
      >
        <lang-select
          class="lang-select"
          size="2x"
        />
        <div class="Coretronic-FMS">
          <img
            :src="`/asserts/icon/logo/signin-${iconLogo}`"
            alt="logo"
            class="fix-logo"
          >
        </div>
        <el-col
          type="flex"
          justify="space-between"
          align="middle"
        >
          <div style="height:134px;" />
          <el-card class="login-card">
            <el-form
              :model="authData"
              @submit.native.prevent="onSignIn"
            >
              <el-form-item label="">
                <div class="login-image">
                  <div class="welcome" v-if="showTitle">
                    {{ $t('button.welcome') }}
                  </div>
                  <img
                    :src="`/asserts/icon/logo/drone-${iconLogo}`"
                    alt="placeholder image"
                  >
                </div>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="authData.username"
                  :placeholder="$t('login.email')"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="authData.password"
                  :placeholder="$t('login.password')"
                  type="password"
                />
              </el-form-item>

              <el-row
                type="flex"
                justify="space-between"
                align="middle"
              >
                <el-checkbox
                  v-model="authData.rememberMe"
                  class="remember"
                >
                  {{ $t('login.rememberMe') }}
                </el-checkbox>
              </el-row>
              <el-form-item>
                <el-button-full
                  :loading="loading"
                  type="primary"
                  native-type="submit"
                >
                  {{ $t('login.signIn') }}
                </el-button-full>
              </el-form-item>
            </el-form>
          </el-card>
          <div style="height:55px;" />
          <div class="about-body">
            <p>Version: {{ config.version.describe }}</p>
            <p>API Version: {{ config.apiVersion }}</p>
            <p>CIRC &copy; 2018  {{ $t('login.right_reserved') }}</p>
          </div>
        </el-col>
      </el-row>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AuthData } from '@/models/account';
import ElButtonFull from '@/components/ElButtonFull.vue';
import LangSelect from '@/components/LangSelect.vue';
import customerSetting from '@/config/customerSetting';

export default {
  name: 'LoginPage',
  components: {
    ElButtonFull,
    LangSelect,
  },
  data() {
    return {
      authData: new AuthData(),
      loading: false,
      config: this.$config,
    };
  },
  computed: {
    ...mapGetters({
      authed: 'auth/isAuth',
      isAdmin: 'auth/isAdmin',
      isFMSAdmin: 'auth/isFMSAdmin',
      customer: 'customer',
    }),
    iconLogo() {
      if (this.customer && customerSetting[this.customer]) {
        return customerSetting[this.customer].logo;
      }
      return `${this.$config.customerSetting.logo}`;
    },
    showTitle() {
      if (this.customer && customerSetting[process.env.VUE_APP_customer]) {
        return customerSetting[process.env.VUE_APP_customer].showTitle;
      }
      return `${this.$config.customerSetting.showTitle}`;
    },
  },
  created() {
    if (this.authed && !this.$route.query.isSignOut) {
      this.$router.push({
        name: 'dashboard',
      });
    }
  },
  mounted() {
    this.getCookie();
  },
  methods: {
    ...mapActions({
      signin: 'auth/signin',
      getProfile: 'user/getProfile',
      changeCustomer: 'changeCustomer',
    }),
    async onSignIn() {
      this.authData.username = this.authData.username.trim();
      try {
        this.loading = true;
        if (!this.authData.rememberMe) {
          this.clearCookie();
        } else {
          window.document.cookie = `CargoCMS_email=${this.authData.username};`;
        }
        const msg = await this.signin(this.authData);
        await this.getProfile(this.authData.username);
        this.$showSuccess(msg);

        const customer = customerSetting.findCustomer(this.authData.username);
        if (customer) {
          this.changeCustomer(customer.name);
        } else {
          this.changeCustomer('');
        }

        this.$router.push({
          name: 'selectGroup',
          query: {
            returnUrl: this.$route.query.returnUrl,
          },
        });
      } catch (error) {
        console.group('onSignIn');
        console.error(error);
        console.groupEnd();
        this.$showFail(error);
      } finally {
        this.loading = false;
      }
    },
    getCookie() {
      if (document.cookie.length > 0) {
        const arr = document.cookie.split('; ');
        for (let i = 0; i < arr.length; i++) {
          const arr2 = arr[i].split('=');
          if (arr2[0] == 'CargoCMS_email') {
            this.authData.username = arr2[1];
            if (arr2[1]) { this.authData.rememberMe = true; }
          }
        }
      }
    },
    clearCookie() {
      window.document.cookie = 'CargoCMS_email=;path=/;expires=0';
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/variables.scss';

body {
  padding-top: 0;
}

.login-page {
  height: 100vh;
  background-color: #e2f6ff;

  .lang-select {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .login-container {
    height: 100%;

    .login-card {
      width: 500px;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 4px;
      border: solid 2px #ffffff;
      border: solid 2px var(--White);

      .login-image {
        overflow: hidden;
        margin-bottom: 20px;

        img {
           max-width: 400px;
        }
        .welcome{
           width: 182px;
          height: 50px;
          font-family: HelveticaNeue;
          font-size: 42px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #4f9fdd;
          margin-bottom: 10px;
          font-weight: bold;
        }
      }

      .remember {
        font-family: HelveticaNeue;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(171, 171, 171, 0.9);
      }
    }

    .about-body {

      p {
        color: #989898;
        font-size: 12px;
      }
    }
  }

  .Coretronic-FMS {
    // width: 128px;
    height: 21px;
    font-family: HelveticaNeue;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0c5c9c;
    position: absolute;
    top: 25px;
    left: 20px;

    .fix-logo {
      max-height:40px;
    }

  }

}

.login-page:before {
  content: '';
  position: absolute;
  width: 100%;
  left: 0%;
  height: 10%;
  background: #ffffff;
}

</style>
